<template>
  <a-result
    status="404"
    title="404 Not Found"
    :sub-title="L('页面不存在，请检查网址是否输入正确！')"
  >
    <template #extra>
      <a-button type="primary" @click="back"> {{L('返回上一层')}} </a-button>
    </template>
  </a-result>
</template>

<script>
export default {
  methods: {
    back() {
      this.$router.go(-1);
    },
  },
};
</script>